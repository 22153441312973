import React from "react"
import { GiThreeLeaves } from "react-icons/gi"
import { AnimationOnScroll } from "react-animation-on-scroll"
// import WhoShouldAttendOne from "../images/indian1.jpg"
import WhoShouldAttendOne from "../images/newDesign/people-taking-part-business-event.jpg"
import WhoShouldAttendTwo from "../images/india2.jpg"
import YouShouldAttend from "../images/indian3.jpg"
import Events from "./event"

export default function attendProgram() {
  return (
    <>
      <Events />

      <div className="mt-5 pt-5 mb-5 px-5">
        <h1 className="heading mb-5"> WHO SHOULD ATTEND</h1>

        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div className="comman-para">
              The Indian SDG Goals of India and the implementation of ESG
              Frameworks impacts each of us in different capacities – as an
              individual employee, organisation, government bodies and social
              enterprises to build a sustainable future.
              <br />
              <br />
              Indian ESG Network will be organising events for various
              stakeholders to provide opportunities for networking and
              collaborating with other organisations.
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 text-center text-lg-end">
            <img
              src={WhoShouldAttendOne}
              className="rounded img-fluid"
              width="88%"
              alt={``}
            />
          </div>
        </div>
      </div>

      <div className="mt-5 pt-5 mb-5 px-5">
        <h1 className="heading mb-5">WHY YOU SHOULD ATTEND</h1>
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div className="card border-0">
              <div className="card-body">
                <ul className="comman-para">
                  <li>
                    A forum for social development leaders to traverse India's
                    broad ESG landscape.
                    <br />
                    Examine the top strategies being implemented by ESG heads at
                    leading corporations.
                  </li>
                  <li>
                    Enables the exchange of ideas and best practises in order to
                    increase social impact and reach.
                  </li>
                  <li>
                    Network and connect with as many partner organisations that
                    interest you and your ESG goals as possible.
                  </li>
                  <li>
                    Opportunity to be a part of a platform that brings together
                    corporations, civil society organisations, ESG enablers,
                    incubators, impact assessors, social entrepreneurs, NGOs,
                    consulting firms, government agencies, and many other
                    organisations participating in the co-creation of
                    sustainable economies.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 text-center text-lg-end">
            <img
              src={YouShouldAttend}
              className="rounded img-fluid"
              width="88%"
              alt={``}
            />
          </div>
        </div>
      </div>
    </>
  )
}
